<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="采购订单入库"
    top="5vh"
    width="1000px"
    :visible.sync="showDialog"
    fullscreen
    destroy-on-close
  >
    <div class="wrapper">
      <el-alert
        :closable="false"
        :title="titleTip"
        type="success"
        style="width: 82%; margin-top: -70px; margin-left: 120px"
      ></el-alert>
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="90px"
      >
        <el-form-item label="供应商:" prop="supp_id">
          <el-select
            v-model="form.supp_id"
            clearable
            placeholder="请选择供应商"
            filterable
            remote
            :remote-method="remoteMethod"
            :loading="loadingS"
            style="width: 180px"
            disabled
            @blur="gycBlur"
            @change="gysChange"
          >
            <el-option
              v-for="(item, index) in gysList"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经办人:" prop="operator_id">
          <el-select
            v-model="form.operator_id"
            clearable
            placeholder="请选择经办人"
            style="width: 180px"
            disabled
          >
            <el-option
              v-for="(item, index) in jbrList"
              :key="index"
              :label="item.user_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库:" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            style="width: 180px"
            clearable
            placeholder="入库仓库"
          >
            <el-option
              v-for="(item, index) in ckList"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item prop="">
                  <el-input
                    placeholder="使用扫码器添加商品"
                    style="width: 303px"
                  ></el-input>
                </el-form-item>-->
        <el-form-item label="" prop="minUnit">
          <el-checkbox v-model="minUnit">按小单位采购</el-checkbox>
        </el-form-item>
        <el-form-item label="" prop="maxUnit">
          <el-checkbox v-model="maxUnit">按大单位采购</el-checkbox>
        </el-form-item>
        <el-form-item label="" prop="auto_update_price">
          <el-checkbox v-model="form.auto_update_price">更新成本价</el-checkbox>
        </el-form-item>

        <!-- 表格 -->
        <el-table
          ref="tableSort"
          v-loading="loading"
          size="mini"
          stripe
          :data="list"
          :summary-method="getSummaries"
          show-summary
          height="520px"
        >
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in columns"
            :key="tableIndex"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            align="center"
          >
            <!-- 类型 -->
            <template v-if="item.prop == 'goods_type'" #default="{ row }">
              <el-select
                v-model="row.goods_type"
                :disabled="!row.editor"
                size="mini"
              >
                <el-option
                  v-for="(typeItem, index) in typeSelect"
                  :key="index"
                  :label="typeItem.value"
                  :value="typeItem.id"
                ></el-option>
              </el-select>
            </template>
            <!-- 商品名称 -->
            <template
              v-else-if="item.prop == 'goods_name'"
              #default="{ $index, row }"
            >
              <goods-search
                ref="goodsSearch"
                size="mini"
                :f-key="row.goods_name"
                :brand-id="form.brand_id"
                :is-brand="form.supp_id == '' ? true : false"
                @add-rows="addRows($event, $index)"
                @select-goods-all="selectGoods($event, row)"
              ></goods-search>
            </template>
            <!-- 单位 -->
            <template v-else-if="item.prop == 'unit_name'" #default="{ row }">
              <el-select
                v-model="row.unit_id"
                size="mini"
                @change="selectUnit($event, row)"
              >
                <el-option
                  v-for="(unitItem, unitIndex) in row.arr_unit"
                  :key="unitIndex"
                  :label="unitItem.unit_name"
                  :value="unitItem.unit_id"
                ></el-option>
              </el-select>
              <!-- {{ row.unit_name }} -->
            </template>
            <!-- 生产日期 -->
            <template
              v-else-if="item.prop == 'goods_production_date'"
              #default="{ row }"
            >
              <el-date-picker
                v-model="row.goods_production_date"
                size="mini"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </template>
            <!-- 采购价 -->
            <template v-else-if="item.prop == 'cost_price'" #default="{ row }">
              <el-input
                v-model="row.cost_price"
                size="mini"
                @input="priceSum($event, row)"
              ></el-input>
              <!--              {{row.goods_price}}-->
            </template>
            <!-- 采购数量 -->
            <template v-else-if="item.prop == 'goods_num'" #default="{ row }">
              <el-input
                v-model="row.goods_num"
                size="mini"
                @input="priceSum($event, row)"
                @focus="inputfocus(row)"
              ></el-input>
            </template>
            <!-- 金额 -->
            <template v-else-if="item.prop == 'sum_money'" #default="{ row }">
              <el-input
                v-model="row.sum_money"
                size="mini"
                @input="moneyChange($event, row)"
              ></el-input>
            </template>
            <!-- 备注 -->
            <template v-else-if="item.prop == 'remark'" #default="{ row }">
              <el-input v-model="row.remark" size="mini" />
            </template>

            <template v-else #default="{ row }">
              {{ row[item.prop] }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            width="100"
          >
            <template #default="{ $index, row }">
              <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
              <el-button
                size="mini"
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item>
          <!--          <el-checkbox
                      v-model="form.auto_update_price"
                      :true-label="1"
                      :false-label="0"
                    >
                      生成采购入库单时重新计算商品成本
                    </el-checkbox>-->
        </el-form-item>
        <div class="pay">
          <div class="left">
            <div
              style="
                width: 80%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-left: 20px;
              "
            >
              <span>采购金额：{{ form.total_amount }}</span>
              <span>待入库：{{ form.in_money_un }}</span>
              <span>实付合计：{{ form.pay_money }}</span>
              <span>剩余应付：{{ form.arrears }}</span>
            </div>
            <el-form-item label="金额合计:">
              <el-input
                v-model="allSum"
                disabled
                style="width: 100px"
              ></el-input>
              <!-- <span style="margin-left: 10px">优惠:{{ yhl }}元</span> -->
            </el-form-item>
            <el-form-item prop="remark">
              <el-input
                v-model="form.remark"
                placeholder="备注"
                style="width: 303px"
              ></el-input>
            </el-form-item>
            <div class="left">
              <div v-for="(payInfo, index) in pay_info" :key="index">
                <el-form-item label="付款金额" style="">
                  <el-input
                    v-model="payInfo.amount"
                    style="width: 100px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" style="">
                  <el-select
                    v-model="payInfo.account_id"
                    placeholder="付款账户"
                    style="width: 100px"
                  >
                    <el-option
                      v-for="(item, index) in fkzhList"
                      :key="index"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <i class="el-icon-circle-plus" @click="addInput"></i>
                <i class="el-icon-remove-outline" @click="deleteInput(index)" />
              </div>

            </div>
            <div style="margin-left: 80%">
              <el-button type="success" @click="handleReview">提交</el-button>
              <el-button @click="close">关闭</el-button>
            </div>
            <!-- <el-form-item label="优惠后应付:" style="width: 100%">
              <el-input
                v-model="form.final_money"
                style="width: 100px"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="付款金额" style="">
              <el-input
                v-model="payInfo.amount"
                style="width: 100px"
              ></el-input>
            </el-form-item>
            <el-form-item label="" style="">
              <el-select
                v-model="payInfo.account_id"
                placeholder="付款账户"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in fkzhList"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" style="">
              <el-select
                v-model="payInfo.user_id"
                placeholder="选择付款人"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in fkrList"
                  :key="index"
                  :label="item.user_name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <i class="el-icon-circle-plus addbtn" @click="addInput"></i>
            </el-form-item> -->
          </div>
          <div class="right">
            <el-table stripe :data="list2" height="200" size="mini">
              <el-table-column
                label="入库单号"
                prop="bill_code"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="仓库"
                prop="depot_id"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="入库金额"
                prop="total_amount"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="经办人"
                prop="operator_name"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="入库时间"
                prop="create_at"
                width="auto"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>

      <!-- 表格结束 -->
    </div>
    <!-- 底部按钮 -->
    <!--    <div-->
    <!--      slot="footer"-->
    <!--      class="dialog-footer"-->
    <!--      style="margin-top: 5px; text-align: right"-->
    <!--    >-->
    <!--      <el-button type="success" @click="handleReview">-->
    <!--        提交-->
    <!--      </el-button>-->
    <!--      <el-button @click="close">关闭</el-button>-->
    <!--    </div>-->
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { postAction } from '@/api/Employee'
  import {
    jbrSelect,
    gysSelect,
    fkzhSelect,
    addPurchaseOrder,
    purchaseOrderDetail,
    purchaseDelete,
    purchaseReview,
    storeOrderDetail,
    storeReview,
  } from '@/api/purchase'
  import { getAllSelect2 } from '@/api/advanceOrder'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: 'Store',
    components: {
      GoodsSearch,
    },
    data() {
      return {
        id: 0,
        bill_code: '',
        create_at: '',
        showDialog: false,
        loading: false,
        jbrList: [],
        fkrList: [],
        gysList: [],
        loadingS: false,
        gysListBK: [],
        ckList: [],
        fkzhList: [],
        allSum: 0,
        minUnit: false,
        maxUnit: false,
        typeSelect: [
          {
            id: 1,
            value: '采购',
          },
          {
            id: 2,
            value: '赠送',
          },
        ],
        form: {
          brand_id: '',
          order_id: '',
          supp_id: '', //供应商id
          operator_id: '', //经办人id
          depot_id: '', //仓库id
          final_money: '',
          return_money: '', //退款金额
          remark: '', //备注
          auto_update_price: false,
        },
        payInfo: {
          account_id: '',
          user_id: '',
          amount: '',
        },
        pay_info: [
          {
            account_id: '',
            account: '',
          },
        ],

        columns: [
          {
            order: 1,
            label: '类型',
            width: '100',
            prop: 'goods_type',
          },
          {
            order: 2,
            label: '商品名称',
            width: '220',
            prop: 'goods_name',
          },
          {
            order: 3,
            label: '规格',
            width: '90',
            prop: 'goods_specs',
          },
          {
            order: 4,
            label: '单位',
            width: '90',
            prop: 'unit_name',
          },
          {
            order: 5,
            label: '采订价',
            width: '160',
            prop: 'goods_price',
          },
          {
            order: 6,
            label: '待入库',
            width: '140',
            prop: 'goods_depotin_leftnum_read',
          },
          {
            order: 7,
            label: '入库价',
            width: '140',
            prop: 'cost_price',
          },
          {
            order: 8,
            label: '入库数量',
            width: '90',
            prop: 'goods_num',
          },
          {
            order: 9,
            label: '入库金额',
            width: '90',
            prop: 'sum_money',
          },
          {
            order: 10,
            label: '生产日期',
            width: '240',
            prop: 'goods_production_date',
          },
          {
            order: 11,
            label: '单位换算',
            width: '100',
            prop: 'goods_uint_change',
          },
          {
            order: 13,
            label: '备注',
            width: '220',
            prop: 'remark',
          },
        ],
        list: [
          {
            goods_type: 1,
            goods_name: '',
            goods_specs: '',
            goods_code: '',
            arr_unit: [],
            goods_uint_change: '',
            goods_production_date: '',
            unit_id: '',
            unit_name: '',
            goods_price: '0',
            goods_num: null,
            sum_money: 0,
            remark: '',
          },
        ],
        list2: [
          {
            account: '',
            amount: '',
            create_name: '',
          },
        ],
      }
    },
    computed: {
      yhl() {
        console.log(this.final_money, this.allSum)
        return Number(this.allSum) - Number(this.form.final_money)
      },
      titleTip() {
        return (
          '单据号:' +
          this.bill_code +
          ' 下单时间： ' +
          this.create_at +
          '  ' +
          this.form.create_name +
          '  ' +
          '审核' +
          this.form.check_at +
          this.form.check_name
        )
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.allSum = 0
          ;(this.maxUnit = false), (this.minUnit = false)
        }
      },
      // 总额
      allSum(val) {
        if (val) {
          this.form.final_money = val
          // this.payInfo.amount = val
        }
      },
      // 按最小单位
      minUnit(val) {
        console.log(val)
        if (val) {
          this.list.forEach((item) => {
            if (item.goods_name !== '') {
              // 单位变小单位
              item.unit_id = item.arr_unit.filter(
                (item2) => item2.unit_type == 1
              )[0].id
              // 编码变小编码
              item.goods_code = item.arr_unit.filter(
                (item2) => item2.id == item.unit_id
              )[0].unit_code
              // //采购价变小单位采购价
              // let price = row.arr_unit.filter((item2) => item2.id == val)[0]
              // let endP = ''
              // if (price.purc_price == 0) {
              //   endP = price.cost_price
              // } else {
              //   endP = price.purc_price
              // }
              // item.goods_price = endP
              this.selectUnit(item.unit_id, item, false)
              this.priceSum('', item)
            }
          })
        }
      },
      // 按最大单位
      maxUnit(val) {
        console.log(val)
        if (val) {
          this.minUnit = false

          this.list.forEach((item) => {
            if (item.goods_name !== '') {
              // 单位变小单位
              item.unit_id = item.arr_unit.filter(
                (item2) => item2.unit_type == 3
              )[0].id
              // 编码变小编码
              item.goods_code = item.arr_unit.filter(
                (item2) => item2.id == item.unit_id
              )[0].unit_code
              this.selectUnit(item.unit_id, item, false)
              this.priceSum('', item)
            }
          })
        }
      },
    },
    created() {
      // 经办人&付款人下拉
      jbrSelect().then((res) => {
        this.jbrList = res.data.rows
        this.fkrList = res.data.rows
      })
      // 供应商下拉
      gysSelect().then((res) => {
        this.gysList = res.data.rows
        this.gysListBK = res.data.rows
      })
      // 仓库下拉
      getAllSelect2().then((res) => {
        this.ckList = res.data
      })
      // 付款账户
      fkzhSelect().then((res) => {
        console.log(res)
        this.fkzhList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        let { data, code, msg } = await storeOrderDetail({
          order_id: this.id,
        })
        console.log(data)
        let dada = JSON.parse(JSON.stringify(data.rows))
        console.log(this.form)
        // tips
        this.bill_code = dada.bill_code
        this.create_at = dada.create_at

        // 供应商
        this.form = JSON.parse(JSON.stringify(dada))
        this.form.depot_id = this.form.depot_id + ''
        this.form.order_id = this.id

        this.list = dada.detail_info.detail
        this.list2 = dada.base_info_list

        this.addRow()
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 元'
          } else {
            sums[index] = 'N/A'
          }
        })
        let a = []
        sums.forEach((item, idx) => {
          if (idx == 9) {
            let jine = item.split(' 元')[0]
            a.push(Number(jine).toFixed(2))
            this.allSum = Number(item.split(' 元')[0]).toFixed(2)
          } else if (idx == 8) {
            let endP = item.split(' 元')[0]
            a.push(Number(endP).toFixed(2))
          } else {
            a.push('')
          }
        })
        return a
      },
      // 采购价事件
      priceSum(val, row) {
        row.sum_money = (row.cost_price * row.goods_num).toFixed(2)
      },
      close() {
        this.showDialog = false
      },
      // 表格内单位切换
      unitChange(val, row) {
        console.log(val, row)
        // let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        // row.goods_price = price
        let price = row.arr_unit.filter((item) => item.id == val)[0]
        let endP = ''
        if (price.purc_price == 0) {
          endP = price.cost_price
        } else {
          endP = price.purc_price
        }
        row.goods_price = endP
      },

      chooseGoodsKeyword(val, row) {},
      copyRow(index, row) {
        let rowdata = JSON.parse(JSON.stringify(row))
        rowdata.editor = true
        this.list.splice(index + 1, 0, rowdata)
      },
      deleteRow(index, row) {
        if (this.list.length <= 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      // 表格添加一行
      addRow() {
        if (
          this.list.length == 0 ||
          this.list[this.list.length - 1].goods_name !== ''
        ) {
          this.list.push({
            goods_type: 1,
            goods_name: '',
            goods_specs: '',
            goods_code: '',
            arr_unit: [],
            goods_uint_change: '',
            goods_production_date: '',
            unit_id: '',
            unit_name: '',
            goods_price: '0',
            goods_num: null,
            sum_money: 0,
            remark: '',
            editor: true,
          })
        }
      },
      inputfocus(row) {
        console.log('input focus')
        if (row.goods_num == 0) {
          row.goods_num = null
        }
      },
      // 商品搜索添加rows
      addRows(val, index) {
        console.log('addRows')
        console.log(val)
        val.forEach((item) => {
          item.goods_uint_change = item.unit_cv
          this.$set(item, 'sum_money', 0)
          this.$set(item, 'cost_price', item.cost_price)
          // this.$set(item, 'goods_num', Number(item.quantity))
          this.$set(item, 'goods_num', null)
          item.goods_specs = item.specs
          // item.goods_num = Number(item.quantity)
          item.editor = true
          // 默认单位
          item.arr_unit.forEach((item2) => {
            if (item2.unit_id == item.unit_id) {
              item.cost_price = item2.cost_price
              item.goods_price = item2.goods_price
            } else {
              if (item2.is_default == '2') {
                console.log(item2)
                item.unit_id = item2.id
                item.goods_code = item2.unit_code
                item.cost_price = item2.cost_price
                item.goods_price = item2.goods_price
              }
            }
          })
          this.list.splice(index, 0, item)
        })
      },
      // 商品选中
      selectGoods(val, row) {
        Object.assign(row, val)
        console.log(val, row)
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
        row.goods_uint_change = val.unit_cv
        row.goods_specs = val.goods_price
        this.$set(row, 'cost_price', val.cost_price)
        // row.cost_price = val.cost_price
        row.arr_unit = val.arr_unit
        // row.unit_id = val.unit_id
        row.goods_production_date = val.goods_production_date
        row.depot_stock_num_text = val.depot_stock_num_text
        row.editor = true

        // 默认单位
        /*row.arr_unit.forEach((item) => {
        if(row.unit_id==item.unit_id){
          row.cost_price = Number(item.cost_price)
          row.goods_price = Number(item.goods_price)
        }else{
          if(item.is_default == '2'){
            row.cost_price = Number(item.cost_price)
            row.goods_price = Number(item.goods_price)
          }
        }
      })*/
        if (val.goods_id > -1) {
          this.addRow()
        }
      },
      // 单位选中
      selectUnit(val, row, changeC = true) {
        if (this.minUnit && changeC) {
          this.minUnit = false
        }
        console.log(val, row)
        row.goods_code = row.arr_unit.filter(
          (item) => item.id == val
        )[0].unit_code

        let price = row.arr_unit.filter((item) => item.id == val)[0]
        let endP = ''
        row.goods_price = price.goods_price
        row.cost_price = price.cost_price
        // if (price.purc_price == 0) {
        //   endP = price.cost_price
        // } else {
        //   endP = price.purc_price
        // }
        // row.goods_price = endP
        this.priceSum('', row)
      },
      // 添加icon
      addInput() {
        console.log(this.payInfo)
        // if (this.pay_info.account_id != ''&&this.pay_info.amount) {
          // this.list2.push(this.payInfo)
          // this.payInfo = {
          //   account_id: '',
          //   user_id: '',
          //   amount: '',
          // }
          this.pay_info.push({
            account_id: '',
            amount: '',
          })
        // } else {
        //   this.$message.error('请选择账户和付款人')
        // }
      },
      deleteInput(index) {
        if (this.pay_info.length > 1) {
          this.pay_info.splice(index, 1)
        } else {
          this.$message.error('至少保留一个付款信息')
        }
      },

      handleReview() {
        let list = JSON.parse(JSON.stringify(this.list))
        let paylist = JSON.parse(JSON.stringify(this.pay_info))
        if (paylist[0].amount!='' && paylist[0].account_id == '') {
          this.$message.error('请选择付款账户')
          return false
        }else if (paylist[0].amount=='' && paylist[0].account_id != ''){
          this.$message.error('请填写付款金额')
          return false
        }

        list.forEach((item, index) => {
          // 处理  如果最后一行是空  去掉空row
          if (item.goods_name == '' && list.length >= 1) {
            list.splice(index, 1)
          }
          item.arr_unit.forEach((item2) => {
            if (item2.id == item.unit_id) {
              item.unit_name = item2.unit_name
            }
          })
        })
        if (list.length == 0) {
          this.$message.error('请添加商品')
          return false
        }
        // sh(this.payInfo)
        // }
        let orderdata = JSON.parse(JSON.stringify(this.form))
        delete orderdata.detail_info
        delete orderdata.log
        delete orderdata.base_info_list
        let data = {
          order_info: JSON.stringify(orderdata),
          goods_info: JSON.stringify(list),
          pay_info: JSON.stringify(paylist),
        }
        console.log(data)
        storeReview(data).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.clearForm()
            this.$emit('refresh')
            this.$message.success(res.msg)
            this.showDialog = false
          }
        })
      },
      handleDelete() {
        purchaseDelete({ order_id: this.id }).then((res) => {
          this.$message.success('已作废')
        })
      },
      gysChange(val) {
        if (val) {
          this.list = []
          this.addRow()
          this.form.brand_id = this.gysList.filter(
            (item) => item.id == val
          )[0].brand_ids
        } else {
          this.form.brand_id = ''
          this.list = []
          this.addRow()
        }
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.minUnit = false
        this.list = []
        this.addRow()
        this.list2 = []
        this.pay_info = [
          {
            account_id: '',
            account: '',
          },
        ]
      },
      addPayRow(row) {
        this.list2.push({
          account: '',
          amount: '',
          create_name: '',
        })
      },
      // 供应商下拉调接口搜索
      async remoteMethod(query) {
        if (query !== '') {
          this.loadingS = true
          let res = await postAction(
            '/baseAdmin/supplier-user/window-view-do',
            { keyword: query }
          )
          console.log('res', res)
          this.gysList = res.data
          this.loadingS = false
        } else {
          this.gysList = this.gysListBK
        }
      },
      gycBlur() {
        setTimeout(() => {
          this.gysList = this.gysListBK
        }, 200)
      },
      // 金额反算价格
      moneyChange($event, row) {
        if (row.goods_num && row.goods_num !== 0) {
          row.cost_price = (Number($event) / Number(row.goods_num)).toFixed(2)
        } else {
          row.cost_price = 0
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .pay {
    display: flex;
    //flex-direction: column;
    justify-content: flex-start;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
    }
  }
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 25px;
    font-size: 32px;
    color: #84bc39;
  }
  .el-dialog__body {
    padding: 0px 20px !important;
  }
</style>
