var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "查看采购订单",
        top: "5vh",
        width: "1000px",
        visible: _vm.showDialog,
        fullscreen: "",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("el-alert", {
            staticStyle: {
              width: "82%",
              "margin-top": "-70px",
              "margin-left": "120px",
            },
            attrs: { closable: false, title: _vm.titleTip, type: "success" },
          }),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商:", prop: "supp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择供应商",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loadingS,
                        disabled: "",
                      },
                      on: { blur: _vm.gycBlur, change: _vm.gysChange },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.gysList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人:", prop: "operator_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择经办人",
                        disabled: "",
                      },
                      model: {
                        value: _vm.form.operator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operator_id", $$v)
                        },
                        expression: "form.operator_id",
                      },
                    },
                    _vm._l(_vm.jbrList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入库仓库:", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "入库仓库",
                        disabled: "",
                      },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.depot_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tableSort",
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    height: "360px",
                  },
                },
                _vm._l(_vm.columns, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      label: item.label,
                      prop: item.prop,
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              item.label === "入库金额/采订金额"
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.goods_depotin_money) +
                                        "/" +
                                        _vm._s(row.goods_order_money) +
                                        " "
                                    ),
                                  ])
                                : item.label === "入库数量/采订数量"
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.goods_depotin_num_read) +
                                        "/" +
                                        _vm._s(row.goods_order_sum_read) +
                                        " "
                                    ),
                                  ])
                                : item.label === "入库均价/采订价"
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.goods_price_avg) +
                                        "/" +
                                        _vm._s(row.goods_price) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _c("el-form-item"),
              _c("div", { staticClass: "pay" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "70%",
                          display: "flex",
                          "justify-content": "space-between",
                          "margin-bottom": "20px",
                          "margin-left": "20px",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v("采购金额：" + _vm._s(_vm.form.total_amount)),
                        ]),
                        _c("span", [
                          _vm._v("待入库：" + _vm._s(_vm.form.in_money_un)),
                        ]),
                        _c("span", [
                          _vm._v("实付合计：" + _vm._s(_vm.form.pay_money)),
                        ]),
                        _c("span", [
                          _vm._v("剩余应付：" + _vm._s(_vm.form.arrears)),
                        ]),
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "70%" },
                        attrs: { stripe: "", data: _vm.list2, height: "200" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "付款人",
                            prop: "create_name",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "金额",
                            prop: "amount",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "账户",
                            prop: "account",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "时间",
                            prop: "create_at",
                            width: "auto",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "el-table",
                      { attrs: { stripe: "", data: _vm.list3, height: "200" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "入库单号",
                            prop: "bill_code",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "仓库",
                            prop: "depot_id",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "入库金额",
                            prop: "total_amount",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "经办人",
                            prop: "operator_name",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "入库时间",
                            prop: "create_at",
                            width: "auto",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("ruilang-display-dialog", {
            ref: "RuilangDisplayDialog",
            attrs: { bill_type: "CD", data_id: _vm.form.order_id, type: 1 },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handlePrint } }, [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }